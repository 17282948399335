import type { ComputedRef, Ref } from '#imports'
import { computed } from '#imports'
import { debouncedRef } from '@vueuse/core'

export default function <Type>(
  items: ComputedRef<Type[]>,
  searchText: Ref<string>,
  searchProp: keyof Type,
): ComputedRef<Type[]> {
  return computed(() => {
    if (!items?.value?.length) {
      return []
    }

    if (!searchText.value) return items.value

    const searchQuery = debouncedRef(searchText, 350)
    return items.value.filter((item) => {
      const value = item[searchProp]

      if (typeof value !== 'string') {
        console.warn(
          `searchProp needs to reference a string on the item you're searching`,
        )
        console.warn(`found: ${value} for ${JSON.stringify(item)}`)
        return false
      }

      const lowercaseValue = value.toLowerCase()
      const lowercaseQuery = searchQuery.value.toLowerCase()

      const parts = lowercaseQuery.split(' ')

      const matches = parts.filter((word) => lowercaseValue.includes(word))

      return parts.length === matches.length
    })
  })
}
